/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Divider, Text, Title, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" name={"x0sjes9owj"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(224, 224, 224, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"paddingTop":0}}>
              
              <Divider style={{"height":4,"backgroundColor":"var(--color-blend--50)"}}>
              </Divider>

              <Text className="text-box fs--26" style={{"maxWidth":1550,"paddingLeft":0}} content={"<span style=\"font-weight: bold; color: rgb(17, 85, 235); background-color: rgb(223, 220, 219);\">POTŘEBUJETE PŘESNÝ A SPOLEHLIVÝ ROZBOR PITNÉ, ODPADNÍ, BAZÉNOVÉ ČI POVRCHOVÉ VODY?</span>"}>
              </Text>

              <Text className="text-box ff--3 fs--24 w--400 lh--16" style={{"maxWidth":1034,"marginTop":6,"marginBottom":0}} content={"<span style=\"color: rgb(41, 98, 223);\"><span style=\"font-weight: bold;\">Jsme akreditovaní specialisté v oblasti analýzy vody s praxí více než 20 let.\n<br>Zajistíme pro Vás odběr, provedeme rozbor a výsledky Vám zašleme online.<br></span><span style=\"font-weight: bold;\">&nbsp;Poskytujeme vysoce kvalitní, rychlé a přesné rozbory vod.<br></span></span><span style=\"font-weight: bold;\"><span style=\"color: rgb(41, 98, 223);\">&nbsp;<br><span style=\"text-decoration-line: underline;\">Provádíme rozbory i odběry :\n</span><br>Pitné vody - pro kolaudace studní, vrtů, vodovodních řadů a přípojek<br>- pro pravidelnou kontrolu kvality\n<br>- v případě havárií vodovodních řadů a úniku vody\n<br><br>&nbsp;Teplé vody z individuálních i hromadných zdrojů\n<br>&nbsp;<br>Bazénové vody a z koupališť ve volné přírodě\n<br><br>&nbsp;Povrchové vody z potoků, rybníků, jezírek, vodotečí, vodních nádrží\n<br>&nbsp;<br>Odpadní vody – z čistíren odpadních vod včetně domovních a z kanalizačních řadů</span><br></span>"}>
              </Text>

              <Divider style={{"height":4,"paddingBottom":0,"backgroundColor":"var(--color-blend--50)"}}>
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"informace-2"} style={{"paddingTop":37,"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 flex--stretch" anim={"2"} animS={"3"} style={{"maxWidth":"","marginBottom":37,"backgroundColor":"var(--color-blend--85)"}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box fs--26" content={"<span style=\"font-weight: bold;\"><br>Akreditace ČIA<br><br></span>"}>
              </Title>

              <Text className="text-box text-box--justify fs--16" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Vodohospodářská laboratoř Říčany s.r.o. je držitelem Osvědčení o akreditaci Českého institutu pro akreditaci (ČIA).<br></span><br>Zkušební laboratoř č. 1531 akreditovaná ČIA podle ČSN EN ISO/IEC 17025:2018<br><br style=\"font-weight: 700; letter-spacing: 0.36px;\"><span style=\"font-weight: bold;\">Laboratoř je pravidelně kontrolována</span>&nbsp; Českým institutem pro akreditaci a také se&nbsp; účastní mezilaboratorních porovnávacích zkoušek.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360,"paddingBottom":17}}>
              
              <Title className="title-box fs--26" content={"<span style=\"font-weight: bold;\"><br>Více než 20 let zkušeností<br><br></span>"}>
              </Title>

              <Text className="text-box text-box--justify" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Vodohospodářská laboratoř Říčany s.r.o.</span> vznikla v roce 1997 oddělením od firmy Vodovody a kanalizace Říčany s.r.o.&nbsp;s cílem poskytnout klientům <span style=\"font-weight: bold;\">vysoce kvalitní, rychlé a přesné rozbory vod.</span> Tímto heslem se firma úspěšně řídí až do současnosti.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box fs--26" content={"<span style=\"font-weight: bold;\"><br>Přesný a spolehlivý způsob testování vody  </span>"}>
              </Title>

              <Text className="text-box text-box--justify" content={"Vodohospodářská laboratoř Říčany s.r.o. poskytuje služby dle platné legislativy.<span style=\"color: var(--black);\"><br><br>Rozbor vody provedený <span style=\"font-weight: bold;\">akreditovanou vodohospodářskou laboratoří</span>&nbsp;<span style=\"font-weight: bold;\">Říčany</span> <span style=\"font-weight: bold;\">je přesný a spolehlivý způsob testování vody.</span> Poznáte kvalitu vaší vody, zjistíte vhodnost vody pro pití a každodenní používání, nebo ukáže na prvky, které se musí z vody odstranit, aby voda byla kvalitní.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"mlme4baz4lb"} style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3 fs--30" style={{"maxWidth":1115,"marginBottom":1,"paddingRight":1}} content={"<span style=\"font-weight: bold; color: var(--black); font-style: italic;\">Proč si nechat udělat rozbor vody u nás?</span>"}>
              </Title>

              <Text className="text-box text-box--center fs--22 w--800" style={{"maxWidth":1094,"marginTop":14,"paddingTop":0}} content={"<span style=\"color: var(--black);\">Akreditace ČIA<br>Operativnost a kvalita<br>Individuální přístup k zákazníkovi<br>Možnost objednání akreditovaného odběru vody profesionálními vzorkaři<br>Oficiální protokol o rozboru vody v co nejkratším čase<br>Na základě výsledku rozboru poskytnutí informace o kvalitě vody<br>Konzultace řešení nevyhovujících ukazatelů vody</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"informace"} style={{"paddingTop":44,"paddingBottom":55}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"maxWidth":1300,"backgroundColor":"var(--color-blend--85)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":480}}>
              
              <Text className="text-box text-box--center fs--20 lh--1" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(30, 84, 192);\">Vodohospodářská laboratoř Říčany s.r.o. poskytuje&nbsp; klientům vysoce kvalitní, rychlé a přesné rozbory vod.&nbsp;</span><br><br></span>"}>
              </Text>

              <Text className="text-box text-box--center fs--20 ls--001 lh--14" content={"<span style=\"color: var(--black); font-weight: bold;\">Vždy se snažíme vyjít vstříc potřebám zákazníků a proto vždy preferujeme individuální přístup.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"}>
              
              <Image className="pl--25 pr--40" src={"https://cdn.swbpg.com/t/26649/b8ab5dcee3a342d0b662a0cbb59a09bb_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1060}} srcSet={"https://cdn.swbpg.com/t/26649/b8ab5dcee3a342d0b662a0cbb59a09bb_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--40" name={"uxwj2hk36d8"} style={{"paddingTop":4,"paddingBottom":0,"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"paddingTop":0}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left fs--18 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">Vodohospodářská laboratoř Říčany, s. r. o.\n<br>Kolovratská 1476,251 01 Říčany\n<br><br>tel.: 323602539\n<br>mobil: 602363180\n<br>e-mail: laboratorkopecna@seznam.cz\n<br><br>IČO 28393601<br>DIČ CZ28393601</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--18 w--400 ls--002 lh--14" style={{"maxWidth":592,"paddingLeft":0,"paddingRight":38}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Příjem vzorků vody:\n</span><br><span style=\"font-weight: normal;\">Pondělí a úterý: 8.00 – 15.30 hod.\n</span><br><span style=\"font-weight: normal;\">Středa: 8.00 - 11.00 hod.\n</span><br><br><span style=\"font-weight: bold;\">Pracovní doba laboratoře:\n</span><br><span style=\"font-weight: normal;\">Pondělí až čtvrtek: 8.00 - 15.30 hod. (po dohodě do 18 hod.)</span><br><span style=\"font-weight: normal;\">Pátek: 8.00 - 14.00 hod.</span><br><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"gr2htj65rlu"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1 mb--80 mt--80" columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}