/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, SeparateLine, SeparateLineWrap, Image, List } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Informace pro zákazníky"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" name={"cg5dgnw1pt6"} style={{"paddingTop":115,"paddingBottom":2}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingBottom":0}}>
              
              <Title className="title-box title-box--center" content={"<span style=\"text-decoration-line: underline;\">INFORMACE PRO ZÁKAZNÍKY</span>"}>
              </Title>

              <Text className="text-box text-box--center ff--3 fs--22 w--500 ls--04 lh--16" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(196, 69, 40);\"><span style=\"text-decoration-line: underline;\">Příjem vzorků vody:\n</span><br>Pondělí a úterý: 8.00 – 15.30 hod.\n<br>Středa: 8.00 - 11.00 hod.\n<br><br><span style=\"text-decoration-line: underline;\">Pracovní doba laboratoře:\n</span><br>Pondělí až čtvrtek: 8.00 - 15.30 hodin (po dohodě do 18 hod.)<br></span><span style=\"color: rgb(196, 69, 40);\">Pátek: 8.00 - 14.00 hod.</span></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"57cmosu3rjw"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"gtzmzxhtkii"} style={{"paddingTop":4,"paddingBottom":57,"backgroundColor":"var(--color-custom-1--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 --full pl--02" style={{"maxWidth":1090,"backgroundColor":"var(--color-blend--85)"}} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box fs--16 ls--001 lh--14" style={{"maxWidth":878,"paddingLeft":1,"paddingRight":0}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Vodohospodářská laboratoř Říčany provádí o rozbory pitných, odpadních, povrchových i bazénových vod.\n<br></span><br><span style=\"font-weight: bold;\">Odběr vzorků vody: </span><br>Vzorek vody (např. k</span>e kolaudaci studny)si můžete <span style=\"color: var(--black);\">odebrat sami nebo Vám jej můžou odebrat naši vzorkaři.<br>Pokud si budete vzorky vody odebírat sami, je potřeba vyzvednout si v laboratoři potřebné vzorkovnice.&nbsp;<br>Průvodky ke vzorkům vody naleznete níže.<br><br>Za akreditovaný odběr našimi vzorkaři účtujeme dle platného ceníku 200 Kč bez DPH + dopravné 18Kč/km bez DPH.&nbsp; <br><br><span style=\"font-weight: bold;\">Příjem vzorků vody: <br></span>Pondělí, Úterý: 8,00 – 15,30 hod; <br>Středa: 8,00 - 11,00, hod.<br><br><span style=\"font-weight: bold;\">Platba: <br></span>Přijímáme hotovost i platební karty.<br>Po předchozí dohodě můžeme vystavit také fakturu.<br><br><span style=\"font-weight: bold;\">Výsledky rozborů:</span><br>Výsledky obdržíte od 7 do 15 pracovních dnů s ohledem na rozsah prováděného rozboru.\n<br>Protokol s výsledky lze vyzvednout osobně v laboratoři nebo jej zašleme elektronicky.&nbsp;<br><br><span style=\"font-weight: bold;\">Konzultace výsledků:</span><br>Laboratoř poskytuje na základě rozboru informace o kvalitě vody s následnou konzultací o řešení nevyhovujících ukazatelů.&nbsp;<br></span><span style=\"color: var(--black);\"><br><span style=\"font-weight: bold;\">Objednávka rozborů a odběrů:</span><br>Objednávky odběrů vody našimi vzorkaři zasílejte na email: laboratorkopecna@seznam.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0}} name={"9b6vkvyr915"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"wb6uhqg4g8d"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"paddingTop":0}} columns={"3"}>
            
            <ColumnWrapper style={{"paddingBottom":0}}>
              
              <Title className="title-box fs--20" content={"Průvodka vzorku pitné vody - formulář:&nbsp;"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--20" style={{"maxWidth":601,"paddingRight":0}} content={"Protokol o odběru a příjmu vzorku odpadní vody str. 1 + 2 formulář:"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"adj0qaomk1e"} style={{"paddingTop":0,"paddingBottom":0}} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26649/75c7ff9f74e8419d9be5f2633f130771_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":466,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/26649/75c7ff9f74e8419d9be5f2633f130771_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/75c7ff9f74e8419d9be5f2633f130771_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/75c7ff9f74e8419d9be5f2633f130771_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/75c7ff9f74e8419d9be5f2633f130771_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":0}}>
              
              <Image src={"https://cdn.swbpg.com/t/26649/22acc40c87c740b3afdde1b8660f76ba_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"paddingTop":0}} srcSet={"https://cdn.swbpg.com/t/26649/22acc40c87c740b3afdde1b8660f76ba_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/22acc40c87c740b3afdde1b8660f76ba_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/22acc40c87c740b3afdde1b8660f76ba_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/22acc40c87c740b3afdde1b8660f76ba_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26649/643673b59d8c420da1e676f33e5cc05a_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/26649/643673b59d8c420da1e676f33e5cc05a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/643673b59d8c420da1e676f33e5cc05a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/643673b59d8c420da1e676f33e5cc05a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/643673b59d8c420da1e676f33e5cc05a_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"epua259gnej"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" name={"0ojiu4aov0l"} style={{"marginTop":2,"paddingTop":0,"paddingBottom":2}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"backgroundColor":"var(--color-blend--85)"}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--center fs--20" style={{"marginTop":14,"paddingLeft":0,"marginBottom":26}} content={"<span style=\"text-decoration-line: underline;\"><br>POSTUP PŘI ODBĚRU VZORKU PITNÉ VODY PRO ANALÝZU:</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"gr0xqfisgzj"} style={{"paddingTop":0,"paddingBottom":9}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1294,"paddingLeft":140,"backgroundColor":"var(--color-blend--85)"}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <List className="text-box text-box--justify ff--1 fs--18" content={"<li>Před odběrem vzorku odtočte či odčerpejte asi 20 litrů vody a odstraňte z kohoutku spořiče a sítka.</li><li>Nejdříve naplňte plastové vzorkovnice tak, až přetékají a pevně je uzavřete.</li><li>Následně naplňte sterilní skleněnou lahev s hliníkovou folií do 3/4 nebo sterilní plastovou nádobu s červeným víčkem opatřenou neporušenou pečetí (je nutno ponechat vzduchovou bublinu). Vzorkovnici ihned uzavřete a na skleněnou lahvičku zpět přitlačte těsně hliníkovou fólii na hrdlo lahve. Je třeba dbát na aseptický odběr!<br></li><li>Nedotýkejte se hrdla či vnitřního uzávěru skleněné ani plastové lahve.<br></li><li>Odebrané vzorky vody dopravte ihned  do laboratoře – nejdéle však do 24 hodin.<br></li><li>Vzorky vody uchovejte i transportujte v chladu do 5°C .<br></li>"} subtype={"unordered"}>
              </List>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--justify" style={{"maxWidth":632}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">UPOZORNĚNÍ:&nbsp;</span><br>Skleněné vzorkovnice na mikrobiologický rozbor jsou použitelné nejdéle šest týdnů od data sterilizace, uvedeného na dně vzorkovnice. Po uplynutí této doby <span style=\"text-decoration-line: underline;\">vraťte</span> vzorkovnice do laboratoře k případné výměně.</span><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ymqeqgx71it"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" name={"je2jgge92i"} style={{"marginTop":0,"paddingTop":4,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1264,"paddingLeft":128}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--justify fs--20 lh--14" content={"<br><span style=\"font-weight: bold; text-decoration-line: underline; color: rgb(175, 23, 7);\">Co dělat když.... aneb máte nevyhovující mikrobiologické výsledky rozboru vaší pitné vody?&nbsp;</span><br><br><span style=\"color: var(--black);\">Kde hledat příčiny?</span><br><br><span style=\"color: var(--black);\">Příčinou zvýšeného výskytu mikroorganismů může být např. pronikání drobných živočichů či rostlinného materiálu do studny, dále pak pronikání povrchové vody či odpadní vody ze septiků, zvýšená či neopatrná aplikace hnojiv v blízkosti studny, případně výkopové práce v blízkosti studny atp.&nbsp;<br><br>Co dělat?&nbsp;<br>V případě, že jste obdrželi protokol a zjistili jste, že výsledky mikrobiologických parametrů vody jsou vyhodnoceny jako nevyhovující, doporučujeme Vám provést tato opatření:<br><br>-&nbsp; vydesinfikujte studnu vhodným desinfekčním přípravkem pro pitnou vodu na bázi chlóru (např. SAVO ORIGINAL použijte dle návodu na lahvi).&nbsp;</span>Důležité je desinfekční prostředek rozmíchat v přibližně 5 litrech vody. Takto připravený zředěný roztok nalijte na povrch vody ve studni a nechte 2 - 3 hodiny působit.&nbsp; Následně roztočte všechny kohoutky v objektu, čímž dojde k propláchnutí a desinfekci všech rozvodů. Voda by měla být slabě cítit chlórem. Pokud voda není cítit chlorem, celý proces zopakujte.<br><span style=\"color: var(--black);\"><br>- nouzovým krátkodobým řešením může být převaření vody, doba varu je minimálně 5 minut</span><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"kt4qeyj20bp"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":20,"paddingBottom":0}} name={"xr6prkxy23"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--20" content={"<span style=\"font-weight: bold;\">Prohlášení o ochraně osobních údajů</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":1210,"marginTop":0}} content={"•\tOsobní údaje zpracováváme z důvodů vyřízení Vašich objednávek na rozbory vod.\n<br>•\tSprávcem osobních údajů podle Nařízení (EU) 2016/679(GDPR) je Správce.\n<br>•\tZpracováváme Vaše jméno a příjmení, adresu, případně adresu místa odběru pokud je odlišná, telefonní číslo a e-mailovou adresu.\n<br>•\tPrávním důvodem tohoto zpracování je plnění smlouvy na rozbor vody.\n<br>•\tOchrana Vašich osobních údajů odpovídá požadavkům Nařízení (EU) 2016/679(GDPR)\n<br>•\tVaše osobní údaje poskytujeme pouze subdodavatelům, pokud se na rozboru vod podílí.\n<br>•\tPři zpracování Vašich osobních údajů nedochází k automatizovanému rozhodování.\n<br>•\tNemáme v úmyslu předávat Vaše osobní údaje do třetí země, mezinárodním organizacím ani jiným subjektům.\n<br>•\tMáte právo kdykoliv odvolat svůj souhlas se zpracováním Vašich osobních údajů, máte právo ode mne jako Správce požadovat přístup ke svým osobním údajům, jejich opravu nebo výmaz.\n<br>•\tMáte právo podat stížnost u Úřadu pro ochranu osobních údajů, máte-li podezření na zpracování Vašich osobních údajů v rozporu s GDPR.\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":26,"paddingTop":0,"paddingLeft":103,"paddingRight":24,"paddingBottom":0,"backgroundColor":"rgba(160, 160, 160, 1)"}} name={"o-nas"}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":606}} content={"<br><span style=\"color: var(--black); font-weight: bold;\">Vodohospodářská laboratoř Říčany, s. r. o.\n<br>Kolovratská 1476,251 01 Říčany\n<br>tel.: 323602539\nmobil: 602363180\n<br>e-mail: laboratorkopecna@seznam.cz\n<br><br>&nbsp;IČO 28393601\nDIČ CZ28393601</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":495}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold; text-decoration-line: underline;\">Příjem vzorků vody:\n</span><br>Pondělí a úterý: 8.00 – 15.30 hod.<br>Středa: 8.00 - 11.00 hod.\n<br><br>&nbsp;<span style=\"text-decoration-line: underline; font-weight: bold;\">Pracovní doba laboratoře:\n</span><br>Pondělí až čtvrtek: 8.00 - 15.30 hod. (po dohodě do 18 hod.)<br>Pátek: 8.00 - 14.00 hod.\n<br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}